import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Image from '../components/image'
import { graphql } from 'gatsby'

import styles from './gifts.module.css'
import PageLinks from '../components/page-links'
import Modal from '../components/modal'
import { classes } from '../util/components'
import SEO from '../components/seo'

const GiftCard = ({ gift }) => {
  const {
    descriptionHtml,
    conditions,
    name,
    shortDescription,
    cardImage,
    modalImage,
    callToAction,
    price,
  } = gift

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <SEO 
        title={"Projector Merchandise | Projector Giftcard | Totebags"}
        description={"Projector Merchandise are great as gifts for your favourite mate, or when you need to impress that special someone, or hey when you just wanna treat yourself!"}
      />
      <div className={`${styles.giftWrapper} flex flex-col`}>
        <div onClick={() => setIsModalOpen(true)}>
          <Image
            className={`${styles.giftImage}`}
            fluid={cardImage && cardImage.childImageSharp.fluid}
          />
        </div>
        <div className={`${styles.giftDetailsContainer} flex flex-col flex-1`}>
          <div className="flex-1">
            <h1 className={styles.giftName}>{name}</h1>
            {shortDescription ? (
              <p className={styles.typeDescription}>{shortDescription}</p>
            ) : (
                ''
              )}
          </div>
          <div
            className={classes(
              'inline-block',
              'flex-initial',
              styles.giftButtonContainer
            )}
          >
            {price && <p className={styles.giftCardPrice}>{price}</p>}
            <button
              className={`${styles.giftButton} btn-primary`}
              onClick={() => setIsModalOpen(true)}
            >
              More Info
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={styles.giftModal}
      >
        <div className="w-full h-full text-left">
          <Image
            className={`${styles.giftModalImage}`}
            fluid={modalImage && modalImage.childImageSharp.fluid}
          />
          <div className={styles.giftModalContent}>
            <h1 className={styles.giftModalName}>{name}</h1>
            <div
              className={`${styles.giftModalDescription}`}
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
            {callToAction && callToAction.linkURL ? (
              <button
                className={`${styles.giftModalCta} btn-primary`}
                href={callToAction.linkURL}
                onClick={event => {
                  typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-gift-now-button`, {
                    event_category: `Gift Purchase`,
                    event_label: 'Projector',
                    name: name,
                    items: [{
                      id: name
                    }]
                  })
                  typeof window !== "undefined" && window.open(callToAction.linkURL, '_blank')
                }}
              >
                {callToAction.label || 'Find Out More'}
              </button>
            ) : (
                ''
              )}

            {price ? (
              <>
                <div className={`${styles.giftModalHeader}`}>Price</div>
                <div className={styles.giftPrice}>{price}</div>
              </>
            ) : null}

            {(conditions || []).length ? (
              <>
                <div className={`${styles.giftModalHeader}`}>Conditions</div>
                <div className={`${styles.giftConditionsList}`}>
                  {conditions.map((c, i) => (
                    <div key={i} className={`${styles.giftCondition}`}>
                      {c}
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  )
}

export const GiftsTemplate = ({ page }) => {
  const {
    html,
    frontmatter: { title, subtitle, gifts, pageLinks },
  } = page

  return (
    <div className="container flex flex-col text-center items-center py-5 lg:py-20">
      <div className="w-full md:w-4/5 lg:w-3/5">
        <h2 className={styles.giftsSubtitle}>{subtitle}</h2>
        <h1 className={styles.giftsTitle}>{title}</h1>
        <div
          className={`${styles.giftsBody} mx-auto`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div
        className={`${styles.giftsWrapper} flex flex-wrap justify-left items-stretch my-20`}
      >
        {gifts.map((g, i) => (
          <div className="w-full mx-0 p-2 md:w-1/2 lg:w-1/3" key={i}>
            <GiftCard gift={g} />
          </div>
        ))}
      </div>
      <div className="w-full mt-24">
        <PageLinks links={pageLinks} />
      </div>
    </div>
  )
}

const GiftsPage = ({ data }) => {
  return <GiftsTemplate {...data} />
}

GiftsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default GiftsPage

export const pageQuery = graphql`
  query GiftsPage {
    page: markdownRemark(fields: { slug: { eq: "/gifts/" } }) {
      frontmatter {
        title
        subtitle
        gifts {
          descriptionHtml
          conditions
          callToAction {
            label
            linkURL
          }
          name
          price
          shortDescription
          cardImage: image {
            childImageSharp {
              fluid(maxWidth: 390, maxHeight: 330, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          modalImage: image {
            childImageSharp {
              fluid(maxWidth: 780, maxHeight: 660, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ...PageLinks
      }
      html
    }
  }
`
